@for $i from 1 through 1000 {
  .w-#{$i} {
    width: $i * 1% !important;
  }
}

@for $i from 1 through 100 {
  .m-top-#{$i} {
    margin-top: $i * 1px !important;
  }
  .left-#{$i} {
    left: $i * 1vw !important;
  }
  .bottom-#{$i} {
    bottom: $i * 1px !important;
  }
  .top-#{$i} {
    top: $i * 1px !important;
  }
  .h-#{$i} {
    height: 100vh !important;
  }
  .font-#{$i} {
    font-size: $i * 1px !important;
  }
  .feather-#{$i} {
    width: $i * 1px !important;
    height: $i * 1px !important;
  }
  .rad-#{$i} {
    border-radius: $i * 1px !important;
  }
}

@for $i from 1 through 1000 {
  .mw-#{$i} {
    max-width: $i * 1px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn:disabled {
  cursor: not-allowed !important;
  pointer-events: inherit !important;
}

.iti {
  width: 100% !important;
}

hr {
  height: 0 !important;
}

.dialog-overlay .mat-dialog-container {
  overflow: overlay !important;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal !important;
}

.table-responsive {
  overflow-x: hidden !important;
  @media screen and (max-width: 1400px) {
    overflow-x: auto !important;
  }
}

.table-hover tbody tr:hover {
  background-color: rgba(var(--bs-light-rgb)) !important;
}

.mat-icon-button {
  line-height: 0 !important;
  height: 0 !important;
}

.fields-icon {
  .mat-icon-button {
    line-height: 24px !important;
    height: 24px !important;
  }
}

.dark-sidebar-logo {
  margin: 0 auto;
  left: 0;
  right: 0;
  float: bottom !important;
}

password-strength-checker {
  .wrapper__text {
    &>span {
      max-width: 90%;
    }
  }
}
