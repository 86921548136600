.snapshot-form {
  .snap-form {
    div {
      margin-left: 5px;
    }
  }
  .icon {
    color: #DDDDDD;
  }
}
