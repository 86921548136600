:root {
  --bs-blue: #0198e0;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0198e0;
  --bs-secondary: #a8aaae;
  --bs-success: #28c76f;
  --bs-info: #00cfe8;
  --bs-warning: #ff9f43;
  --bs-danger: #ea5455;
  --bs-light: #dfdfe3;
  --bs-dark: #4b4b4b;
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 75, 75, 75;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 111, 107, 125;
  --bs-body-bg-rgb: 248, 247, 250;
  --bs-font-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bs-font-monospace: "Public Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bs-gradient: linear-gradient(135deg, #0198e0 22.16%, #2ab7f9c2 76.47%);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.47;
  --bs-body-color: #6f6b7d;
  --bs-body-bg: #f8f7fa;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 0.625rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0198e0;
  --bs-link-hover-color: #02a6f3;
  --bs-btn-hover-color: #fff!important;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.875rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: #dbdade;
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  --bs-card-inner-border-radius: 0.375rem;
  --bs-card-cap-padding-y: 1.5rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.5rem;
  --bs-card-group-margin: 0.75rem;
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
}

.nav,
.nav-pills {
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: var(--bs-primary) !important;
}

.nav-pills .nav-link:not(.active):hover {
  color: var(--bs-primary);
}

a {
  text-decoration: none;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 0.25rem 1.125rem rgb(75 70 92 / 10%);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: none;
  border-radius: var(--bs-card-border-radius);
}

.card-primary.card-outline {
  border-top: 3px solid var(--bs-primary);
}

.btn {
  border-radius: 0.375rem;
  border: none;

  &.btn-primary {
    background-color: var(--bs-primary);
    background-image: linear-gradient(135deg, #0198e0 22.16%, #2ab7f9c2 76.47%);
    box-shadow: 0 0.25rem 1.125rem rgb(75 70 92 / 10%);
  }

  &.btn-tool {
    &:hover {
      @apply text-black;
    }
  }
}

.mat-elevation-z4 {
  box-shadow: 0 0.5rem 1.125rem rgb(75 70 92 / 10%);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.icheck-primary>input:first-child:checked+input[type=hidden]+label::before, .icheck-primary>input:first-child:checked+label::before {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.page-item.active .page-link {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

app-root {
  &.sidebar-collapse {
    app-menu-sidebar {
      .brand-image {
        margin-left: 0 !important;
      }
      .info {
        display: none;
      }
      .nav-link {
        text-align: center;
      }
      p {
        display: none!important;
        margin-left: 0 !important;
      }
      
    }

    .main-sidebar:hover .nav-link {
      text-align: center;
      width: 3.6rem!important;
      
      .brand-image {
        margin-left: 0 !important;
      }

      .info {
        display: none;
      }

      p {
        display: none;
        margin-left: 0 !important;
      }
    }
  }
  .bg-primary {
    background-color: var(--bs-primary) !important;
  }
}

.elevation-4 {
  box-shadow: 0 14px 28px rgb(75 70 92 / 10%), 0 10px 10px rgb(75 70 92 / 10%) !important;
}

.content-header {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.badge-light {
  background-color: #dee2e6;
}

.form-control {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0.125rem 0.25rem rgb(165 163 174 / 30%);
}

.table-responsive {
  overflow-x: auto!important;
}

.tab-header {
  padding-right: 0.35rem;
  padding-left: 0.35rem;
  @apply mb-4;
  .card-header {
    @apply flex justify-between items-start md:items-center;
  }

  .card-body {
    max-height: 180px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
    }
    &::-webkit-scrollbar-track {
      @apply rounded-3xl bg-neutral-100;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-3xl border-2 border-transparent bg-gray-300 hover:bg-slate-400;
      background-clip: content-box;
    }
  }

  .projected-content {
    @apply grid grid-cols-1 md:grid-cols-3 gap-2 gap-y-3 font-light;
    p {
      margin-bottom: 0;
      br {
        font-size: 2rem;
      }

      & > span {
        @apply font-semibold mr-2;
      }
    }
  }
}

.modal-dialog {
  .btn.btn-default {
    @apply text-black;
    &:hover {
      @apply text-black;
    }
  }
}
