%content {
  color: white;
  position: relative;
  top: 2px;
  margin-left: 28px;
  font-size: .8em;
}

.details-switch {
  .status-switch {
    &.form-check-input {
      background-image: url('/assets/img/switch-off.png') !important;
      background-color: $switch-off-bg;
      margin-left: 0;
      width: 42px !important;
      height: 19px;
      border-radius: 27px;
      border: none;

      &:checked {
        background-image: url('/assets/img/switch-on.png') !important;
        border: none;
        background-color: $switch-on-bg;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .state-switch {
    &.form-check-input {
      background-image: url('/assets/img/switch-off-2.png') !important;
      background-size: 24%;
      background-position: 5%;
      background-color: $delete-bg;
      margin-left: 0;
      margin-bottom: 12px;
      border-radius: 27px;
      border: none;
      width: 78px;
      height: 25px;

      &:checked {
        background-image: url('/assets/img/switch-on-2.png') !important;
        border: none;
        background-position: 5%;
        background-color: $default-menu-item;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .state-switch[data-state = 'Actif']::after {
    content: 'Actif';
    @extend %content;
  }

  .state-switch[data-state = 'Bloqué']::after {
    content: 'Bloqué';
    @extend %content;
  }
}
