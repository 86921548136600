.alert-danger {
    color: #842029 !important;
    background-color: #f8d7da !important;
    border-color: #f5c2c7 !important;
}
.alert-primary {
  color: #084298 !important;
  background-color: #cfe2ff !important;
  border-color: #b6d4fe !important;
}
