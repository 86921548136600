/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url("https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/css/select2.min.css");
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// @import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import 'bootstrap/scss/bootstrap';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';



@import '~ngx-toastr/toastr';

@import "utils/mat-themes";
@import "utils/variables";

@import "base/utilities";
@import "base/custum-alert";
@import "components/modal";
@import "components/switch";
@import "components/mat-table";
@import "components/create-repository";
@import "components/upload-file";
@import "components/switch-button";
@import "components/user-profile.scss";
@import "components/fields_table";


@import "pages/snapshot-form";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "utils/template";
@import "utils/font-awesome/font-awesome";


body {
  width: 100vw;
  height: 100vh;
  background: #f8f7fa;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.user-menu > .nav-link {
  cursor: pointer;
}

.user-menu > .nav-link:after {
  content: none;
}

.user-menu > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
  height: 175px;
  padding: 10px;
  text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
  z-index: 5;
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  font-size: 17px;
  margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
  display: block;
  clear: both;
  content: '';
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-body a {
    background: #ffffff !important;
    color: #495057 !important;
  }
}

.user-menu > .dropdown-menu > .user-footer {
  background-color: #f8f9fa;
  padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
  display: block;
  clear: both;
  content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #6c757d;
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f8f9fa;
  }
}

.user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2.1rem;
  margin-right: 10px;
  margin-top: -2px;
  width: 2.1rem;
}

@media (min-width: 576px) {
  .user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-right: 0.4rem;
    margin-top: -8px;
  }
}

.user-menu .user-image {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0;
  margin-left: -8px;
}

.dropdown-toggle.custom::after {
  display:none !important;
}

.show-more {
  width: 420px;
}

.login-page, .register-page {
  background:  linear-gradient(to bottom, rgba(0, 0, 0, .15), rgba(0, 0, 0, .25)), url('../assets/img/background.jpg') center no-repeat;
  background-size: cover;
}
