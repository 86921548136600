.upload-file {
  span {
    //font: normal normal 300 15px/29px Poppins;
    // color: grey;
  }

  &__card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #B3B1B2;
    width: 100%;
    height: 170px;

    i {
      font-size: 25px;
      // color: black;
    }

  }

  .download-text {
    position: relative;
    bottom: 18px;
  }
  .output {
    img {
      max-width: 220px;
      max-height: 126px;
    }
  }

}
