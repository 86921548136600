.add-repository {

  .mat-step-label.mat-step-label-active {
  //  color: $secondary !important;
  }
  .mat-step-label-selected {
    color: black;
  }
  .mat-step-label {
    color: black;
  }
  .mat-step-header .mat-step-icon {
   // background: $default-menu-item;
    color: white;
  }
  .mat-step-icon-selected, .mat-step-icon-state-edit, .mat-step-icon-state-done {
    // background: $secondary !important;
  }
  .mat-step-icon-state-error {
    color: red !important;
  }
  .mat-step-label-selected {
    font-weight: normal !important;
  }
  .mat-horizontal-content-container {
    overflow: hidden;
    padding: 0 !important;
  }

  td.mat-cell {
    padding: 5px !important;
    border: none !important;
  }
  th.mat-header-cell {
    border: none;
    padding-left: 11px !important;
  }

  .field {
    div {
      margin-left: 5px;
      margin-right: 5px;
    }
  }


  .upload-file {
    span {
      //font: normal normal 300 15px/29px Poppins;
     // color: grey;
    }

    &__card {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 0.5px solid #B3B1B2;
      width: 100%;
      height: 170px;

      i {
        font-size: 25px;
       // color: black;
      }

    }

    .download-text {
      position: relative;
      bottom: 18px;
    }
    .output {
      img {
        max-width: 220px;
        max-height: 126px;
      }
    }

  }
}
