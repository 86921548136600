.modal-sm {
  max-width: 600px !important;
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: 500px !important;
  }
}

.modal-lg {
  max-width: 800px !important;
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: 500px !important;
  }
}

.modal-xl {
  max-width: 1200px !important;
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: 500px !important;
  }
}

.modal-open {
  overflow: hidden;
}

