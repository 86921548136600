
.user {
    &__profile {
        .picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
        }
    }
}